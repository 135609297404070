import Extension from "@common/js/Naja/Extension";


const MODE_DELAYED = 'delayed';
const MODE_CONTINUOUS = 'continuous';
const CLASS = 'in-viewport';
const DATA = 'data-in-viewport';

class JobQueue {
    private jobs: (() => void)[] = [];
    private delay = 200;
    private timeout: NodeJS.Timeout = null;

    private start() {
        this.timeout = setTimeout(this.worker, this.delay);
    }

    public add(job: () => void) {
        this.jobs.push(job);
        if (!this.timeout) this.start();
    }

    private worker = () => {
        const job = this.jobs.shift();
        job();
        if (this.jobs.length) {
            this.start();
        } else {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
    }
}


export default class InViewportController extends Extension {

    private readonly selector: string = '[data-in-viewport]:not(.in-viewport)';
    private readonly options: IntersectionObserverInit = {
        rootMargin: screen.width < 769 ? '0px' : '100px',
        threshold: screen.width < 769 ? 0.1 : (screen.width < 1200 ? 0.5 : 0.5)
    };
    private readonly thresholds = {};
    private readonly queues = {
        0: new JobQueue(),
        1: new JobQueue(),
    };

    initSnippet(root: HTMLElement) {
        const elements: NodeListOf<HTMLElement> = root.querySelectorAll(this.selector);
        elements.forEach(element => {
            const threshold: number = parseFloat(element.dataset.inViewport) || this.options.threshold as number;
            if (!this.thresholds[threshold]) this.thresholds[threshold] = []
            this.thresholds[threshold].push(element);
        });
        for (let thresholdsKey in this.thresholds) {
            const observer = new IntersectionObserver(this.observe, {...this.options, threshold: parseFloat(thresholdsKey)});
            this.thresholds[thresholdsKey].forEach(element => {
                observer.observe(element);
            });
        }
    }

    observe = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
        for (let entry of entries) {
            let target = entry.target as HTMLElement;
            let type = target.getAttribute(DATA);
            const isIntersecting = entry.isIntersecting;
            if (type === MODE_DELAYED) {
                if(isIntersecting){
                    const callback = () => target.classList.add(CLASS);
                    this.queues['1'].add(callback);
                    setTimeout(callback,1000);
                }/*else{
                    const callback = () => target.classList.remove(CLASS);
                    this.queues['0'].add(callback);
                    setTimeout(callback,1000);
                }*/
                observer.unobserve(target);
            } else {
                target.classList.toggle(CLASS, isIntersecting);
                if (isIntersecting && type !== MODE_CONTINUOUS) {
                    observer.unobserve(target);
                }
            }

        }
    }

    private readonly observer: IntersectionObserver = new IntersectionObserver(this.observe, this.options);
}
