import {getCookie, setCookie} from "@common/js/cookies";

const cookieBar = (
    barSelector: string = "#cookiebar",
    btnSelector: string = "#cookiebar-close",
    classNameRemove: string = "javascript:hidden",
    cookieKey: string = "closedCookieBar"
) => {

    const closeBar = () => {
        setCookie(cookieKey, true, 365);
        el.classList.add(classNameRemove);
    }

    const el = document.querySelector(barSelector);
    const btn = document.querySelector(btnSelector);
    const closedCookie = getCookie(cookieKey);

    if (!el) return
    if (closedCookie) return;
    el.classList.remove(classNameRemove);
    if (!btn) return;
    btn.addEventListener("click", closeBar);
}

export default cookieBar;
