const units = {s: 1000, ms: 1};
const regex = /(?<time>[\d.]+)(?<unit>\w+)/;
const defaultDuration = 350;

const time2Mils = (style: string) => {
    const {time, unit} = regex.exec(style).groups;
    return Number(time) * (units[unit] || 1);
}
export default function collapse() {
    const buttons = document.querySelectorAll('button[data-collapse]') as NodeListOf<HTMLButtonElement>;
    for (let button of buttons) {
        button.addEventListener('click', () => {
            const selector = button.getAttribute('data-collapse');
            if (selector) {
                const collapses = document.querySelectorAll(selector) as NodeListOf<HTMLElement>;
                for (let collapse of collapses) {
                    const style = window.getComputedStyle(collapse);
                    const time = Number(collapse.dataset.time || time2Mils(style.transitionDuration) || defaultDuration);
                    let open;
                    if (collapse.classList.contains('collapse')) {
                        open = collapse.classList.contains('open');
                    } else {
                        collapse.classList.add('collapse');
                        open = style.maxHeight !== '0px';
                    }
                    if (open) {
                        collapse.style.maxHeight = collapse.clientHeight + 'px'
                        setTimeout(() => {
                            collapse.classList.remove('open');
                            collapse.style.removeProperty('max-height');
                        }, 0);
                    } else {
                        collapse.style.maxHeight = collapse.scrollHeight + 'px';
                        collapse.classList.add('open');
                        setTimeout(() => {
                            collapse.style.removeProperty('max-height');
                        }, time);
                    }
                    button.setAttribute('aria-expanded', `${!open}`);
                }
            }
        });
    }
}

