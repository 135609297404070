
import Extension from "@common/js/Naja/Extension";

export default class CircleOffsetAnimator extends Extension {
    private readonly transitionClass = "transition-stroke-dashoffset";

    constructor(
        private readonly selector: string = ".js-circle",
    ) {
        super();
    }

    initSnippet(root: HTMLElement) {
        const circleParent: HTMLElement = root.querySelector('.js-circle');
        if (circleParent) {
            const circle = circleParent.querySelector('circle');
            const maxValue = circleParent.dataset.max;
            const currentValue: number = Number(circleParent.dataset.current);
            const radius = circle.r.baseVal.value;
            const circumference = radius * 2 * Math.PI;

            function setProgress(percent) {
                // @ts-ignore
                const offset = circumference - percent / 100 * circumference;
                // @ts-ignore
                circle.style.strokeDashoffset = offset;
            }

            circle.classList.remove(this.transitionClass);
            circle.style.strokeDasharray = `${circumference} ${circumference}`;
            setProgress(0);
            let callback = (entries, observer) => {
                entries.forEach(entry => {
                    if (!entry.isIntersecting) return;
                    circle.classList.add(this.transitionClass);
                    // @ts-ignore
                    const onePercent = 100 / maxValue;
                    const percent = onePercent * currentValue;
                    setProgress(percent);
                });
            };
            /*let observer = new IntersectionObserver(callback);
            observer.observe(circleParent);*/
            const animateCircle = () => {
                circle.classList.add(this.transitionClass);
                // @ts-ignore
                const onePercent = 100 / maxValue;
                const percent = onePercent * currentValue;
                setProgress(percent);
            }
            const mutationCallback = (mutationsList, observer) => {
                for (const mutation of mutationsList) {
                    if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                        if (mutation.target.querySelector('.js-circle')) {
                            setTimeout(() => {
                                animateCircle();
                            }, 400)
                        }
                    }
                }
            };
            let mutationObserver = new MutationObserver(mutationCallback);
            root.querySelectorAll("[data-in-viewport]").forEach(el => mutationObserver.observe(el, {attributes: true}))
        }
    }
}
