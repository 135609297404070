import SwiperCore, {Navigation, Keyboard, Autoplay,A11y} from 'swiper';
import type {SwiperOptions} from "swiper/types/swiper-options";
import Extension from "@common/js/Naja/Extension";
import {AfterUpdateEvent} from "naja/dist/core/SnippetHandler";

const modules = () => [Navigation, Keyboard, Autoplay,A11y];
const selectorKeys = ['nextEl', 'prevEl', 'el', 'swipeHandler'];
const processData = (data: SwiperOptions, el: HTMLElement): SwiperOptions => {
    if (data === null || data === undefined) return {};
    for (let key in data) {
        const val = data[key];
        if (typeof val === "object") {
            processData(val, el);
        } else if (typeof val === 'string') {
            if (selectorKeys.includes(key) && val.at(0) === '.') {
                data[key] = el.querySelector(val);
            }
        }
    }
    return data;
};

const processConfig = (el: SwiperHTMLElement, config: SwiperOptions): SwiperOptions => {
    return {...config, ...processData(JSON.parse(el.dataset.swiper || '{}'), el)};
}

interface SwiperHTMLElement extends HTMLElement {
    Swiper?: SwiperCore
}


export default class SwiperExtension extends Extension {

    private readonly navigationOptions = {
        nextEl: '.slider-arrow-right',
        prevEl: '.slider-arrow-left',
    }

    private readonly configs: Record<string, (el: SwiperHTMLElement) => SwiperOptions> = {
        '.swiper-common': () => ({
            modules: modules(),
            speed: 300,
            slidesPerView: 1,
            centeredSlides: true,
            spaceBetween: 20,
            navigation: this.navigationOptions,
            breakpoints: {
                640: {slidesPerView: 1, spaceBetween: 40},
                769: {slidesPerView: 1, spaceBetween: 60},
            },
        }),
        '.swiper-mobile': () => ({
            modules: modules(),
            speed: 300,
            slidesPerView: 1.15,
            spaceBetween: 20,
            navigation: this.navigationOptions,
            breakpoints: {
                640: {slidesPerView: 1.05, spaceBetween: 40},
                769: {slidesPerView: 1, spaceBetween: 60},
            },
        }),
        '.swiper-years': () => ({
            modules: modules(),
            speed: 300,
            slidesPerView: 1.15,
            spaceBetween: 20,
            breakpoints: {
                640: {slidesPerView: 2, spaceBetween: 40},
                //768:  {slidesPerView: 2.2, spaceBetween: 40},
                769: {slidesPerView: 2, spaceBetween: 60},
                1080: {slidesPerView: 3, spaceBetween: 60},
                1200: {slidesPerView: 3, spaceBetween: 80}
            },
            navigation: this.navigationOptions
        }),
        '[data-swiper]': (el: SwiperHTMLElement) => ({
            speed: 300,
            //spaceBetween: 20,
            slidesPerView: 1,
            ...processData(JSON.parse(el.dataset.swiper || '{}'), el),
            on: {
                init(swiper: SwiperCore) {
                    for (let button of el.querySelectorAll<HTMLElement>('[data-go-to]')) {
                        const slide = button.matches('.swiper-side') ? button : button.closest('.swiper-slide');
                        const index = Number(button.dataset.goTo) || [...slide.parentElement.children].indexOf(slide);
                        if (index >= 0) {
                            button.addEventListener('click', () => swiper.slideTo(index));
                        } else {
                            console.warn('Destination slide not found')
                        }
                    }
                },
            },
        })
    };

    private readonly configEntries: [string, (el: SwiperHTMLElement) => SwiperOptions][] = Object.entries(this.configs);


    protected initSnippet(snippet: Element, event: AfterUpdateEvent | null = null) {
        for (let [selector, configFactory] of this.configEntries) {
            for (let el of snippet.querySelectorAll<SwiperHTMLElement>(selector)) {
                if (!el.Swiper) el.Swiper = new SwiperCore(el, processConfig(el, configFactory(el)));
            }
        }
    }
}
