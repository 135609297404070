import nette from "nette-forms"
import naja from "naja";
//import SmoothScrollExtensionNaja from "../../common/js/Naja/SmoothScrollExtension.naja";
import cookieBar from "./utils/Cookiebar";
import collapse from "./utils/collapse";
import toggle from "./utils/toggle";
import SwiperExtension from "./naja/SwiperExtension";
import NavScrollExtension from "./utils/NavScrollController";
import Rellax from "rellax";

naja.formsHandler.netteForms = nette;

naja.registerExtension(new NavScrollExtension({once: false}));
naja.registerExtension(new SwiperExtension());
//naja.registerExtension(new SmoothScrollExtensionNaja({defaultOffset: 200}));

nette.onDocumentReady(() => {
    cookieBar();
    collapse();
    toggle();
    naja.initialize();
    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
    if (!mediaQuery.matches) {
        new Rellax('.rellax', {
            center: true
        });
    }
});
