import type {Extension as NajaExtension} from "naja/dist/Naja";
import type {Naja} from "naja";
import type {AfterUpdateEvent, BeforeUpdateEvent} from "naja/dist/core/SnippetHandler";

export default abstract class Extension implements NajaExtension {

    protected naja: Naja;

    public initialize(naja: Naja): void {
        this.naja = naja;
        this.startup();
        this.initSnippet(document.body);
        naja.snippetHandler.addEventListener('beforeUpdate', this.beforeUpdate);
        naja.snippetHandler.addEventListener('afterUpdate', this.afterUpdate);
    }

    protected beforeUpdate = (event: BeforeUpdateEvent) => this.destroySnippet(event.detail.snippet, event);
    protected afterUpdate = (event: AfterUpdateEvent) => this.initSnippet(event.detail.snippet, event);

    protected initSnippet(snippet: Element, event: AfterUpdateEvent | null = null): void {

    }

    protected destroySnippet(snippet: Element, event: BeforeUpdateEvent): void {

    }

    protected startup(): void {

    }
}
