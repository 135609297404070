import Extension from "@common/js/Naja/Extension";
import {AfterUpdateEvent} from "naja/src/core/SnippetHandler";

interface NavBgControllerOptions {
    selector?: string,
    toggleClasses?: string[],
    offset?: number,
    once?: boolean,
}

export default class NavScrollExtension extends Extension
{
    private isToggled: boolean = false;
    private nav: HTMLElement;

    private options: NavBgControllerOptions = {
        selector: ".nav-main",
        toggleClasses: ["scrolled"],
        offset: 50,
        once: false
    }

    constructor(options?: NavBgControllerOptions) {
        super();
        Object.assign(this.options,options)
    }

    protected initSnippet(snippet: Element, event: AfterUpdateEvent | null = null) {
        const nav: HTMLElement = snippet.querySelector(this.options.selector);
        if (!nav) return;
        this.nav = nav;
        this.isToggled = false;
        if (this.nav) {
            document.addEventListener("scroll",this.checkScroll);
            this.checkScroll();
        }
    }

    checkScroll=(e?: Event)=> {
        if (window.pageYOffset > this.options.offset && !this.isToggled) {
            this.nav.classList.add(...this.options.toggleClasses);
            this.isToggled = true;
            (this.options.once) &&
                document.removeEventListener("scroll",this.checkScroll);
        } else if (window.pageYOffset < this.options.offset && this.isToggled) {
            this.nav.classList.remove(...this.options.toggleClasses);
            this.isToggled = false
        }
    };
}
