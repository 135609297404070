export default function toggle() {
    const buttons = document.querySelectorAll('[data-toggle]') as NodeListOf<HTMLButtonElement>;
    for (let button of buttons) {
        button.addEventListener('click', () => {
            const selector = button.getAttribute('data-toggle');
            const toggleClass = button.getAttribute('data-toggle-class') || 'toggled';
            const toggles = selector ? document.querySelectorAll(selector) : [button];
            for (let toggle of toggles) {
                let open;
                if (toggle.classList.contains('toggle')) {
                    open = toggle.classList.contains(toggleClass);
                } else {
                    toggle.classList.add('toggle');
                }
                if (open) {
                    setTimeout(() => {
                        toggle.classList.remove(toggleClass);
                    }, 0);
                } else {
                    toggle.classList.add(toggleClass);
                }
                button.setAttribute('aria-expanded', `${!open}`);
            }
        });
    }
}

